import {FC, useCallback, useEffect, useContext, useState} from "react";
import {Box, Typography, Skeleton} from "@mui/material";
import {themeDark} from "../../../../styles/theme";
import {useTypedSelector} from "hooks/useTypedSelector";
import {useAppDispatch} from "hooks/useAppDispatch";
import {pairTickersGetThunk} from "store/exchange/exchange.thunk";
import {StyledSelectPair,} from "./styles";
import {MarketPlaceDrawer} from "../../../../components/Drawers/MarketPlaceDrawer";
import {useDrawerManager} from "../../../../hooks/useDrawerManager";
import {EDrawerID} from "../../../../types/common.types";
import {CoinIcon} from "../../../../components/UI/atoms/CoinIcon/CoinIcon";
import { CentrifugeContext } from "providers/CentrifugeProvider";

interface IProps {}

export const ExchangeHeader: FC<IProps> = ({}) => {
  const tickers = useTypedSelector((state) => state.exchange.tickers);
  const selectedPair = useTypedSelector(state => state.exchange.selectedPair)
  const {socketLoading} = useTypedSelector(state => state.exchange)
  const dispatch = useAppDispatch();
  const socketContext = useContext(CentrifugeContext)

  useEffect(() => {
    if(selectedPair) {
      dispatch(pairTickersGetThunk(selectedPair.ID));
    }
  }, [selectedPair])


  const {setDrawerId} = useDrawerManager()

  const handleClick = useCallback(() => {
    setDrawerId(EDrawerID.markets)
  }, [])

  console.log(tickers)

  return (
      <Box
          component={"div"}
          sx={{ background: "#181B25", marginBottom: "5px" }}
          height={75}
          display={"flex"}
          alignItems={"start"}
          justifyContent={"space-between"}
          pt={20 / 8}
          pl={1.5}
          pb={20 / 8}
          pr={30 / 8}
      >
        <MarketPlaceDrawer/>
        <Box
            display={"flex"}
            lineHeight={1}
            alignItems={"center"}
            height={"100%"}
        >
          {
            selectedPair && <CoinIcon coinName={selectedPair?.BaseCurrency}/>
          }

          <Box lineHeight={1}>
            <Typography component={"span"} variant={"body2"}>
              {selectedPair?.BaseCurrency}
            </Typography>
            <Typography
                component={"span"}
                style={{ color: themeDark.palette.primary.contrastText }}
                variant={"body2"}
            >
              /{selectedPair?.QuoteCurrency}
            </Typography>
          </Box>
        </Box>

        <Box
            display={"flex"}
            lineHeight={1}
            height={"100%"}
            justifyContent={"left"}
            alignItems={"start"}
            flexDirection={"column"}
        >
          {/* <StyledSelect
          value={currentPairId}
          onChange={(e) => setCurrentPairId(e?.target?.value)}
					IconComponent={Icon}
        >
          {pairs?.map((pair, index) => {
            return (
              <MenuItem
                key={pair.ID}
                value={pair.ID}
              >{`${pair.BaseCurrency}/${pair.QuoteCurrency}`}</MenuItem>
            );
          })}
        </StyledSelect> */}
          <StyledSelectPair onClick={handleClick}>
            <Typography>All markets</Typography>
            <p>
              <img src="/images/exchange/chewronDown.svg" alt="" />
            </p>
          </StyledSelectPair>
        </Box>
        <Box
            display={"flex"}
            lineHeight={1}
            height={"100%"}
            justifyContent={"left"}
            alignItems={"start"}
            flexDirection={"column"}
            width={'160px'}
        >
          <Typography sx={{ color: "secondary.main" }}>
            Last market price
          </Typography>
          {
            !tickers?.lastMarketPrice ?
            <Skeleton width={100} height={21}/> :
            <Typography variant={"h6"} sx={{ color: "success.main" }}>
              {tickers?.lastMarketPrice ? Number(tickers.lastMarketPrice).toFixed(5) : ""}
            </Typography>
          }
        </Box>

        <Box
            display={"flex"}
            lineHeight={1}
            height={"100%"}
            justifyContent={"left"}
            alignItems={"start"}
            flexDirection={"column"}
            width={'240px'}
        >
          <Box>
            <Typography component={"span"} sx={{ color: "secondary.main" }}>
              24h Volume:{" "}
            </Typography>
            {
              !tickers?.volume24h ?
              <Skeleton width={70} height={16} style={{display: 'inline-flex', position: 'relative', top: '1px'}}/> :
              <Typography component={"span"} sx={{ color: Number(tickers.volume24h) < 0 ? '#f44336' : "success.main" }}>
                {tickers.volume24h || ""}
              </Typography>
            }
          </Box>
          <Box>
            <Typography component={"span"} sx={{ color: "secondary.main" }}>
              Market volume:{" "}
            </Typography>
            {
              !tickers?.marketVolume ?
              <Skeleton width={70} height={16} style={{display: 'inline-flex', position: 'relative', top: '1px'}}/> :
              <Typography component={"span"}>{tickers.marketVolume} </Typography>
            }
            
          </Box>
        </Box>

        <Box
            display={"flex"}
            lineHeight={1}
            height={"100%"}
            justifyContent={"left"}
            alignItems={"start"}
            flexDirection={"column"}
        >
          <Box>
            <Typography component={"span"} sx={{ color: "secondary.main" }}>
              24h High:{" "}
            </Typography>
            {
              !tickers?.high24H ?
              <Skeleton width={70} height={16} style={{display: 'inline-flex', position: 'relative', top: '1px'}}/> :
              <Typography component={"span"}>{tickers.high24H ? Number(tickers.high24H).toFixed(5) : ""}</Typography>
            }
            
          </Box>
          <Box>
            <Typography component={"span"} sx={{ color: "secondary.main" }}>
              24h Low:{" "}
            </Typography>
            {
              !tickers?.low24H ?
              <Skeleton width={70} height={16} style={{display: 'inline-flex', position: 'relative', top: '1px'}}/> :
              <Typography component={"span"}>{tickers.low24H ? Number(tickers.low24H).toFixed(5) : ""}</Typography>
            }
            
          </Box>
        </Box>
      </Box>
  );
};
