import {FC, Fragment} from 'react'
import {Box, ListItem, Typography} from "@mui/material";
import { IBalanceItem, ICurrency, IWalletBalance } from '../../../../types/exchange.types';
import {ExchangeBalanceItem} from "../ExchangeBalanceItem/ExchangeBalanceItem";

interface IProps {
    items: IWalletBalance
}


export const ExchangeBalances: FC<IProps> = ({items}) => {
    return <Box sx={{background: '#181B25', padding: '17px 13px', marginTop: '5px'}}>
        <Typography variant={'body2'}>
            Balances
        </Typography>
        <ListItem component={'div'} sx={{flexDirection: 'column', maxHeight: 350, overflowY: 'auto'}}>
        {
            (items.balances && items.balances.length) && 
            items.balances.map((item, i) => <Fragment key={i}>
                <ExchangeBalanceItem key={i} value={item}/>
            </Fragment>)
        }
        </ListItem>
    </Box>
}

