import { styled } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export const StyledMenu = styled(Menu)(() => ({
  "& .MuiPaper-root": {
    background: "#FFFFFF",
  },
}));

export const StyledMenuItem = styled(MenuItem)(() => ({
  background: "#FFFFFF",
  color: "#000000",
}));
export const LinkTitle = styled("div")(() => ({
  fontFamily: 'Poppins',
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
   color: "#000000",
}));
export const LinkTitleBeta = styled("div")(() => ({
	fontFamily: "Poppins",
	fontWeight: 400,
	fontSize: "16px",
	lineHeight: "24px",
	color: "#000000",
	display: "flex",
	"&:after": {
		content: '"BETA"',
		paddingLeft: 7,
		paddingTop: 5,
		fontFamily: "Poppins",
		fontStyle: "normal",
		fontWeight: 600,
		fontSize: "10px",
		lineHeight: "15px",
		color: "#00b7f1",
	},
}));
export const LinkDesc = styled("span")(() => ({
  fontFamily: 'Poppins',
  fontWeight: 400,
  fontSize: "12px",
  maxWidth:300,
  lineHeight: "18px",
   color: "#000000"
}));
