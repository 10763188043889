import React, { FC, ReactNode, useEffect, useLayoutEffect } from "react";

import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";

import { useCustomTheme } from "../../hooks/useCustomTheme";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import styles from "./authLayout.module.scss";

interface IProps {
	children: ReactNode;
}

export const AuthLayout: FC<IProps> = ({ children }) => {
	//States
	const navigate = useNavigate();
	const location = useLocation();
	const { setTheme } = useCustomTheme();
	//Redux
	const { isLogin } = useTypedSelector((state) => state.profile);

	//Effects
	useEffect(() => {
		if (isLogin) {
			navigate("/");
		}
	}, [isLogin]);

	useLayoutEffect(() => {
		setTheme!("dark");
	}, []);

	return (
		<>
			<div className='h-screen w-full dark'>
				<div className={styles["auth-top-bar"]}>
					<Link to='/trading'>
						{/* <a> */}
						<img src='/images/logo.png' alt='logo' height={40} />
						{/* </a> */}
					</Link>

					<img src='/images/auth/globe.svg' alt='global' height={31} width={31} />
				</div>
				<div className={styles["auth-wrapper"]}>
					{location.pathname === "/signup" ? (
						<>
							<div className={styles["signup-circle"]}>
								<img alt='' src='/images/auth/circle.svg' />
							</div>
							<div className={styles["signup-circle"]}>
								<img alt='' src='/images/auth/circle.svg' />
							</div>
							<div className={styles["signup-circle"]}>
								<img alt='' src='/images/auth/circle.svg' />
							</div>
						</>
					) : (
						<>
							<div className={styles["signin-circle"]}>
								<img alt='' src='/images/auth/circle.svg' />
							</div>
							<div className={styles["signin-circle"]}>
								<img alt='' src='/images/auth/circle.svg' />
							</div>
						</>
					)}
					<div className={styles["auth-container"]}>{children}</div>
				</div>
			</div>
		</>
	);
};
