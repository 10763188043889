import styled from '@emotion/styled';
import {
  DialogTitle,
  Input,
} from '@mui/material';

export const StyledDialogTitle = styled(DialogTitle)(() => ({
    padding: "16px 0 0 0",
    margin: "0 24px",
    // marginBottom: 30,
    fontSize: 18,
    fontWeight: 600,
    minWidth: 425,
    color:" #419E6A"
  }));

export const StyledDialogDesc  =  styled("span")(() => ({
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "16px",
     color: "#727272",
     margin: "11px 24px 0 24px",
  }));
  export const DialogContent = styled("div")(() => ({
    margin: "24px 24px",
    display: "flex",
    flexDirection: "row"
 }));

  export const StyledSpenBlock = styled("div")(() => ({
    display: "flex",
    width:"50%",
    flexDirection: "column"
  }));
  export const StyledCurrencyBlock = styled("div")(() => ({
    display: "flex",
    gap: 16,
    width:"50%",
    flexDirection: "row",
    marginTop:16
  }));
  export const Currency = styled("div")(() => ({
    fontFamily: 'Poppins',
    fontWeight: 600,
   
    fontSize: "20px",
    lineHeight: "30px",
    color: "#000",
  }));
  export const StyledSpenBlockTitle = styled("span")(() => ({
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
     color: "#727272",
  }));

  export const  StyledSpenBlockAmount = styled("div")(() => ({
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "30px",
    color: "#000",
  }));

export const ButtonsBlock = styled("div")(() => ({
    display: "flex",
    flexDirection: "row",
    gap: 20,
    margin: "24px "
}));


export const StyledExchangeModalButton = styled("button")(({theme}) => ({
    width: "213px",
    cursor: "pointer",
    height: "40px",
    display: "inline-block",
    border: "1px solid #00b7f1",
    borderRadius: "3px",
    textAlign: "center",
    backgroundColor: "#00b7f1",
    padding: "0",
    color: "white",
    fontSize: "14px",
    fontWeight: "600",
    ":hover": {
        backgroundColor: "none"
      },
}))
export const StyledExchangeModalToWalet = styled("button")(({theme}) => ({
    width: "213px",
    height: "40px",
    border: "none",
    display: "inline-block",
    cursor: "pointer",
    borderRadius: "3px",
    textAlign: "center",
    backgroundColor: "#80848E",
    padding: "10px 0",
    color: "white",
    fontSize: "14px",
    fontWeight: "600",
    ":hover": {
        backgroundColor: "none"
      },
}))


export const StyledCoinDialogTitle = styled(DialogTitle)(() => ({
  padding: "21px 36px 34px  36px",
    // margin: "21px 24px 34px 24px",
    // marginBottom: 30,
    fontSize: 18,
    fontWeight: 600,
    minWidth: 425,
    color:" #000"
  }));

  export const StyledInputSearch = styled(Input)(({ theme }) => ({
    width: 329,
    margin: "0 auto",
    marginBottom: 31,
    "& input": {
      "&::placeholder": {
        color: "#80848E",
        opacity: 1,
      },
    },
  }));