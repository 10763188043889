import { FC } from "react";

import { NavLink, useLocation } from "react-router-dom";

import { Box, Icon, IconButton, styled } from "@mui/material";

import { MarketPlaceDrawer } from "../../../../components/Drawers/MarketPlaceDrawer";
import { useDrawerManager } from "../../../../hooks/useDrawerManager";
import { EDrawerID, INavItem } from "../../../../types/common.types";

interface IProps {}

export const ExchangeNav: FC<IProps> = ({}) => {
	const location = useLocation();

	const { setDrawerId } = useDrawerManager();

	const handleClick = () => {
		setDrawerId(EDrawerID.markets);
	};

	return (
		<StyledNav
			component={"nav"}
			position={"absolute"}
			display={"flex"}
			flexDirection={"column"}
			width={92}
			pt={4}>
			<Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
				{firstBlock.map((item, i) => (
					<StyledLink key={i} to={item.path}>
						<StyledIconButton>
							<Icon>
								<img src={location.pathname === item.path ? item.activeIcon : item.icon} />
							</Icon>
						</StyledIconButton>
					</StyledLink>
				))}

				<StyledLink onClick={handleClick} to={"?pairId=1"}>
					<StyledIconButton>
						<Icon>
							<img src={"/images/navigation/sidebar.svg"} />
						</Icon>
					</StyledIconButton>
				</StyledLink>
				<MarketPlaceDrawer />
			</Box>
		</StyledNav>
	);
};

const StyledNav = styled(Box)`
	top: 81px;
	left: 0;
	bottom: 0;
	z-index: 2;
	background: rgb(23, 26, 36);
`;

const StyledLink = styled(NavLink)`
	margin-bottom: 43px;

	&:last-of-type {
		margin-bottom: 0;
	}
`;

const StyledIconButton = styled(IconButton)`
	.MuiIcon-root {
		width: 30px;
		height: 30px;
	}
`;

const firstBlock: INavItem[] = [
	{
		icon: "/images/navigation/tradeMenuIcon.svg",
		activeIcon: "/images/navigation/tradeMenuActiveIcon.svg",
		path: "/exchange",
	},
	{
		icon: "/images/navigation/list-checks.svg",
		activeIcon: "/images/navigation/list-checks-active.svg",
		path: "/exchange/orders",
	},
];
