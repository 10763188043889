import "./dashboardStyles.scss";

import { FC } from "react";

import { useTypedSelector } from "hooks/useTypedSelector";
import { NavLink } from "react-router-dom";

import { Button } from "@mui/material";

const NotificationBlock: FC = () => {
	const { me } = useTypedSelector((state) => state.profile);

	return (
		<>
			{me && !me?.verification.kyc && (
				<div className='background notificationBlock'>
					<div className='notificationContent'>
						<img src='/images/warning.png' alt='warning' className='icon' />
						<div className='text'>
							<span style={{ fontWeight: 600, fontSize: "15px", lineHeight: "22.5px" }}>
								Account not verified
							</span>
							<span style={{ fontSize: "10px", lineHeight: "15px", color: "#80848E" }}>
								Verify your account in 3 minutes to get full access like deposits and withdrawls
							</span>
						</div>
					</div>
					<div className='notificationContent'>
						<NavLink to='/dashboard/verification'>
							<Button
								className={"default-case"}
								style={{ margin: 0 }}
								sx={{
									minWidth: 100,
									minHeight: 23,
									ml: 53 / 8,
									border: "1.5px solid #00b7f1",
									color: "#00b7f1",
									fontSize: 8,
									fontWeight: 700,
									lineHeight: "10px",
								}}>
								{" "}
								Verify now
							</Button>
						</NavLink>
					</div>
				</div>
			)}
		</>
	);
};

export default NotificationBlock;
