import "./style.scss";

import { ChangeEvent, FC, useEffect, useState } from "react";

import { AdditionalLayout } from "components";
import { useNavigate } from "react-router-dom";

import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Alert, Grid, IconButton, Skeleton } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Snackbar from "@mui/material/Snackbar";

import { bankApi, IPaymentReq } from "../../api/bank.api";
import { DPaymentItem } from "../../components/UI/molecules/DPaymentItem/DPaymentItem";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { getBalanceThunk } from "../../store/wallets/wallets.thunk";
import {
	StyledBalanceTitle,
	StyledBalanceValue,
	StyledBalanceValueCurrency,
	StyledButton,
	StyledCancelIcon,
	StyledCheckCircleIcon,
	StyledGridContainer,
	StyledGridItemPlug,
	StyledGridItemPlugMain,
	StyledGridItemTitle,
	StyledInput,
	StyledInputAdornment,
	StyledPaymentMethodFormControl,
	StyledPaymentMethodtitle,
	StyledSpepsText,
	StyledVerificationItem,
	WorkItem,
	WorkItemText,
	WorkItemTitle,
} from "./Deposit.style";

const Deposit: FC = (): JSX.Element | null => {
	const dispatch = useAppDispatch();
	const nav = useNavigate();
	const { data } = useTypedSelector((state) => state.wallets.balance);
	const me = useTypedSelector((state) => state.profile.me);
	const [paymentInfo, setPaymentInfo] = useState<IPaymentReq>({
		amount: 0,
		currency: "",
	});
	const [disableContinueBtn, setDisableContinueBtn] = useState(false);
	const [errors, setErrors] = useState<string>();
	const [disabledContinue, setDisabledContinue] = useState<boolean>(false);
	const [verificationSnackbar, setVerificationSnackbar] = useState<boolean>(false);

	useEffect(() => {
		dispatch(getBalanceThunk());
	}, []);

	useEffect(() => {
		if (me?.verification) {
			if (me.verification.level < 2) {
				setVerificationSnackbar(true);
				setDisabledContinue(true);
			}
		}
	}, [me?.verification]);

	const paymentHandler = async () => {
		bankApi
			.createPayment(paymentInfo)
			.then((res) => window.open(res.redirect))
			.catch((err) => console.log("oops"));
	};

	const currencyCheckHandler = (event: ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setPaymentInfo({ ...paymentInfo, currency: value });
	};
	const amountHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { value } = event.target;
		if (value.trim() === "" || !value) {
			setErrors("Amount is under zero or no amount is provided!");
			setDisableContinueBtn(false);
			setAmount(+value);
			setPaymentInfo({ ...paymentInfo, currency: value });
			return;
		}
		setPaymentInfo({ ...paymentInfo, amount: Number(value) });
		setDisableContinueBtn(true);
		setErrors("");
	};
	const [openAmountError, setOpenAmountError] = useState(false);
	const { error } = useTypedSelector((state) => state.wallets.balance);
	const [errorAlert, setErrorAlert] = useState<boolean>(false);
	const [amount, setAmount] = useState(0);
	useEffect(() => {
		setErrorAlert(!!error);
	}, [error]);

	const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}

		setErrorAlert(false);
	};

	const walletBalance = useTypedSelector((state) => state.wallets.walletBalance);
	const verification = useTypedSelector((state) => state.profile.me?.verification);

	return (
		<AdditionalLayout>
			<StyledGridContainer container justifyContent='space-between'>
				<Grid item xs={7.5}>
					<StyledGridItemPlugMain>
						<StyledGridItemTitle container justifyContent='space-between' alignItems='center'>
							<Grid xs={4} item></Grid>
							<Grid xs={4} item>
								Deposit Euro
							</Grid>
							<Grid xs={4} item>
								{/* <NavLink to="/dashboard/withdraw">
                  <StyledButtonLink
                    endIcon={<ArrowRightAltIcon />}
                    variant="text"
                  >
                    Withdraw Fiat
                  </StyledButtonLink>
                </NavLink> */}
							</Grid>
						</StyledGridItemTitle>
						<StyledBalanceTitle>Total balance</StyledBalanceTitle>
						<StyledBalanceValue>
							<StyledBalanceValueCurrency>€</StyledBalanceValueCurrency>
							{walletBalance.data && walletBalance.data.total !== null ? (
							<p>
								<span className={"web25"}>
									{walletBalance.data
										? Number(walletBalance.data.total).toLocaleString("en-US", {
												style: "currency",
												currency: "EUR",
										  })
										: (0.0).toLocaleString("en-US", { style: "currency", currency: "EUR" })}
								</span>
							</p>
							) : (
								<Skeleton />
							)}
							{/* {balance === 0 ? "0.00" : balance?.toLocaleString("en-EN")}{" "} */}
							{/** TODO add dynamic locale */}
						</StyledBalanceValue>

						<Box display='flex' justifyContent='center'>
							<FormControl variant='standard'>
								{/* <StyledInputLabel htmlFor="standard-adornment-password">
                  Amount
                </StyledInputLabel> */}
								<StyledInput
									type='number'
									onKeyDown={(evt) =>
										["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
									}
									placeholder='Enter amount (minimum €20,00)'
									style={{ width: "416px" }}
									onChange={(val) => amountHandler(val)}
									id='standard-adornment-password'
									endAdornment={<StyledInputAdornment position='end'>EUR</StyledInputAdornment>}
								/>
								{/* <StyledWarningText >Minimum amount of deposit is 20 euro</StyledWarningText> */}
								{/* {errors && (
                 
                )} */}
							</FormControl>
						</Box>

						<StyledPaymentMethodtitle>Payment method</StyledPaymentMethodtitle>

						<StyledPaymentMethodFormControl>
							<RadioGroup
								aria-labelledby='demo-radio-buttons-group-label'
								defaultValue='SEPA'
								onChange={(val) => {
									currencyCheckHandler(val);
								}}
								name='radio-buttons-group'>
								{/*<DPaymentItem value={'SEPA'} label={'SEPA Transfer'} image={'sepa.svg'} />*/}
								<DPaymentItem value={"Mastercard"} label={"Mastercard"} image={"mastercard.svg"} />
								<DPaymentItem value={"VISA"} label={"VISA"} image={"visa.svg"} />
								{/*<DPaymentItem value={'iDeal'} label={'iDeal'} image={'ideal.svg'} />*/}
							</RadioGroup>
						</StyledPaymentMethodFormControl>

						<Box style={{ fontFamily: "Poppins" }} display='flex' justifyContent='center'>
							<StyledButton
								style={{ fontFamily: "Poppins" }}
								variant='outlined'
								disabled={
									Boolean(paymentInfo.amount < 20 || paymentInfo.currency === "") ||
									disabledContinue
								}
								onClick={() => paymentHandler()}>
								<span style={{ fontFamily: "Poppins" }}>Continue</span>
							</StyledButton>
						</Box>
						{/* <pre>{JSON.stringify({
              disabledContinue,
              amount: paymentInfo.amount < 20,
              currency: paymentInfo.currency === '',
              total: Boolean(paymentInfo.amount < 20 || paymentInfo.currency === '') || disabledContinue
            }, null, 2)}</pre> */}
					</StyledGridItemPlugMain>
				</Grid>
				<Grid item xs={4}>
					<StyledGridItemPlug>
						<StyledGridItemTitle>Verification steps</StyledGridItemTitle>
						<StyledSpepsText>
							The following steps are required before being able to deposit Fiat (€)
						</StyledSpepsText>

						<StyledVerificationItem
							// display="flex"
							// alignItems="center"
							// justifyContent="space-between"
							container>
							<Grid item xs={7} style={{ fontFamily: "Poppins", fontWeight: 600, fontSize: 12 }}>
								Email address
							</Grid>
							<Grid
								item
								xs={5}
								display='flex'
								style={{ fontFamily: "Poppins", fontWeight: 600, fontSize: 12 }}
								alignItems='center'>
								{verification?.email ? <StyledCheckCircleIcon /> : <StyledCancelIcon />}
								{verification?.email ? "Verified" : "Not verified"}
							</Grid>
						</StyledVerificationItem>

						<StyledVerificationItem
							display='flex'
							alignItems='center'
							justifyContent='space-between'>
							<Grid item xs={7} style={{ fontFamily: "Poppins", fontWeight: 600, fontSize: 12 }}>
								Phone number
							</Grid>
							<Grid
								style={{ fontFamily: "Poppins", fontWeight: 600, fontSize: 12 }}
								item
								xs={5}
								display='flex'
								alignItems='center'>
								{verification?.phone ? <StyledCheckCircleIcon /> : <StyledCancelIcon />}
								{verification?.phone ? "Verified" : "Not verified"}
							</Grid>
						</StyledVerificationItem>

						<StyledVerificationItem
							display='flex'
							alignItems='center'
							justifyContent='space-between'>
							<Grid item xs={7} style={{ fontFamily: "Poppins", fontWeight: 600, fontSize: 12 }}>
								Identity document
							</Grid>
							<Grid
								style={{ fontFamily: "Poppins", fontWeight: 600, fontSize: 12 }}
								item
								xs={5}
								display='flex'
								alignItems='center'>
								{verification?.kyc ? <StyledCheckCircleIcon /> : <StyledCancelIcon />}
								{verification?.kyc ? "Verified" : "Not verified"}
							</Grid>
						</StyledVerificationItem>

						{/* <StyledVerificationItem
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs={7}>Address</Grid>
              <Grid item xs={5} display="flex" alignItems="center">
                {verification?.kyc ? (
                  <StyledCheckCircleIcon />
                ) : (
                  <StyledCancelIcon />
                )}
                {verification?.kyc ? 'Filled in' : 'Not filled in'}
              </Grid>
            </StyledVerificationItem> */}
					</StyledGridItemPlug>

					<StyledGridItemPlug>
						<StyledGridItemTitle>How it works</StyledGridItemTitle>

						<WorkItem>
							<img src='/images/works/works1.svg' alt='Select payment method' />
							<WorkItemTitle>Select payment method</WorkItemTitle>
							<WorkItemText>Enter amount and choose your preferred method of payment</WorkItemText>
						</WorkItem>

						<WorkItem>
							<img src='/images/works/works2.svg' alt='Transfer credit to your BitTrade wallet' />
							<WorkItemTitle>Transfer credit to your BitTrade wallet</WorkItemTitle>
							<WorkItemText>Enter amount and choose your preferred method of payment</WorkItemText>
						</WorkItem>

						<WorkItem>
							<img src='/images/works/works3.svg' alt='Select payment method' />
							<WorkItemTitle>Start buying, holding and trading</WorkItemTitle>
							<WorkItemText>Use the Fiat in your wallet to buy and trade crypto</WorkItemText>
						</WorkItem>
					</StyledGridItemPlug>
				</Grid>
			</StyledGridContainer>
			<Snackbar
				open={openAmountError}
				autoHideDuration={6000}
				onClose={setOpenAmountError.bind(null, false)}>
				<Alert
					onClose={setOpenAmountError.bind(null, false)}
					severity='error'
					sx={{ width: "100%" }}>
					Minimum amount of deposit is 20 euro
				</Alert>
			</Snackbar>

			<Snackbar open={errorAlert} autoHideDuration={6000} onClose={handleClose}>
				<Alert onClose={handleClose} severity='error' sx={{ width: "100%" }}>
					{(error && error.detail) || "An error has occurred"}
				</Alert>
			</Snackbar>
			<Snackbar open={verificationSnackbar}>
				<Alert
					className='alert_verification'
					severity='error'
					onClose={() => setVerificationSnackbar(false)}
					sx={{ alignItems: "center", background: "#D3302F", color: "#fff !important" }}>
					To deposit verification level 2 is required: pass identity verification and facial scan
					<IconButton
						style={{ marginLeft: "10px", color: "#fff" }}
						onClick={() => nav("/dashboard/verification")}>
						<ArrowRightAltIcon />
					</IconButton>
				</Alert>
			</Snackbar>
		</AdditionalLayout>
	);
};

export default Deposit;
