// import Image from 'next/image'
// import Link from 'next/link'
import React, { FC, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "../additionalLayoutStyles.scss";

interface IMenuItem {
	title: string;
	link: string;
	icon: string;
	activeIcon: string;
	isActive: boolean;
}

const MenuItem: FC<IMenuItem> = (props) => {
	const { title, link, icon, activeIcon, isActive } = props;

	const [currentIcon, setCurrentIcon] = useState(isActive ? activeIcon : icon);
	const [_isActive, set_isActive] = useState(isActive);

	useEffect(() => {
		if (link.includes("affiliates")) {
			if (
				document.location.href.includes("make-a-request") ||
				document.location.href.includes("withdraw-comission")
			) {
				set_isActive(true);
				setCurrentIcon(activeIcon);
			}
		}
	}, [isActive]);

	return (
		<>
			<NavLink to={link} className='item' style={{ color: _isActive ? "#00b7f1" : "#000" }}>
				<span className='item__hr' style={_isActive ? { background: "#00b7f1" } : {}}></span>
				<img src={currentIcon} alt={`${title} navigation icon`} height={30} width={30} />
				{title}
			</NavLink>
		</>
	);
};

export default MenuItem;
