import {
  FC,
  useEffect,
  useMemo,
  useContext,
  useState
} from "react";
import {
  Box,
  styled,
  Table,
  TableHead,
  Typography,
  TableCell,
  TableBody,
  TableRow,
  CircularProgress
} from "@mui/material";
import { IBookOrder } from "../../../../types/exchange.types";
import { useTypedSelector } from "hooks/useTypedSelector";
import { pairBookGetThunk } from "store/exchange/exchange.thunk";
import { useAppDispatch } from "hooks/useAppDispatch";
import {
  StyledBookItemBackground,
  StyledTableRow,
  StyledTableCellLeft,
  StyledTableCellRight,
  StyledLoadingBook,
  StyledOrderBookHeader,
  StyledOrderBookWrapper,
  StyledOrderBookRow,
  StyledOrderBookPrice,
  StyledOrderBookAmount,
  StyledOrderBookTotal,
  StyledOrderBookProgress,
  StyledOrderBookMarketPrice
} from "./ExchangeBook.styles";
import {findMinValueInOrderBook} from "../../../../utils/exchange.utils";
import { CentrifugeContext } from "providers/CentrifugeProvider";

interface IProps {
  // ordersSale: IBookOrder[]
  // ordersBuy: IBookOrder[]
}

export const ExchangeBook: FC<IProps> = () => {
  const socketContext = useContext(CentrifugeContext)
  const dispatch = useAppDispatch()
  // const { sell, buy } = useTypedSelector((state) => ({
  //   sell: state.exchange.level2.Asks,
  //   buy: state.exchange.level2.Bids,
  // }));
  const {
    socketLoading,
    selectedPair
  } = useTypedSelector(state => state.exchange)
  const [allTotalSell, setAllTotalSell] = useState<number>(0)
  const [allTotalBuy, setAllTotalBuy] = useState<number>(0)
  
  const sell = useMemo(() => socketContext.level2?.asks || [], [socketContext.level2])
  const buy = useMemo(() => socketContext.level2?.bids || [], [socketContext.level2])

  useEffect(() => {
    if (socketContext.level2 && socketContext.level2.asks && socketContext.level2.bids) {
      let totalSell = 0
      let totalBuy = 0

      socketContext.level2.asks.forEach(ask => totalSell += (Number(ask[1]) * Number(ask[0])))
      socketContext.level2.bids.forEach(bid => totalBuy += (Number(bid[1]) * Number(bid[0])))

      setAllTotalSell(totalSell)
      setAllTotalBuy(totalBuy)
    }
  }, [socketContext.level2])

  // console.log(sell.slice(-15))
  // console.log(sell.slice(0, 15))

  useEffect(() => {
    dispatch(pairBookGetThunk(1))
  }, [])

  // const getWidthBuy = (total: number) => {
  //   const totalAll = buy.reduce((accumulator: number, current: any) => {
  //     return accumulator + Number(current[1] || 0)
  //   }, 0)

  //   return total / totalAll * 100
  // }

  // const getWidthSell = (total: number) => {
  //   const totalAll = sell.reduce((accumulator: number, current: any) => {
  //     return accumulator + Number(current[1] || 0)
  //   }, 0)

  //   return total / totalAll * 100
  // }

  const computeAveragePriceOfPair = useMemo(() => {
    if(sell && buy) {
      const sellMin = sell.length ? +(findMinValueInOrderBook(sell as Array<any[]>)[0]) : 0
      const buyMin = buy.length ? +(findMinValueInOrderBook(buy as Array<any[]>)[0] ): 0

      if(sellMin === 0 && buyMin > 0) return buyMin
      if(buyMin === 0 && sellMin > 0) return sellMin
      return (+sellMin + +buyMin) / 2
    }
    return 0
  }, [sell, buy])

  console.log(socketContext.level2)

  return (
    <StyledBook
      position={"relative"}
      component={"div"}
      width={"100%"}
      height={"100%"}
    >
      <Typography variant={"body2"} style={{ marginBottom: 14 }}>
        Book
      </Typography>
      <Box>
        <StyledOrderBookHeader>
          <span>Price</span>
          <span>Amount</span>
          <span>Total</span>
        </StyledOrderBookHeader>
        {
          socketContext.level2 === null ?
          (
            <StyledLoadingBook>
              <CircularProgress />
            </StyledLoadingBook> 
          )
          // : sell.length === 0 && buy?.length === 0 ?
          // (
          //   <p style={{ height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', fontSize: 18, fontWeight: 400 }}>...Empty</p>
          // )
          :
        (
          <>
            <StyledOrderBookWrapper>
              {
                sell.map((sellOrder, index) => (
                  <StyledOrderBookRow key={index}>
                    <StyledOrderBookPrice style={{color: 'rgba(216, 50, 50, 1)'}}>{sellOrder[0] && Number(sellOrder[0]).toFixed(5)}</StyledOrderBookPrice>
                    <StyledOrderBookAmount>{sellOrder[1] && Number(sellOrder[1]).toFixed(5)}</StyledOrderBookAmount>
                    <StyledOrderBookTotal>{(sellOrder[0] && sellOrder[1]) && (Number(sellOrder[0]) * Number(sellOrder[1])).toFixed(2)}</StyledOrderBookTotal>
                    <StyledOrderBookProgress style={{
                      background: 'rgba(216, 50, 50, 0.15)',
                      width: sellOrder[0] && sellOrder[1] ? `${((Number(sellOrder[0]) * Number(sellOrder[1])) / allTotalSell) * 100}%` : 0
                    }}/>
                  </StyledOrderBookRow>
                ))
              }
            </StyledOrderBookWrapper>
            <StyledOrderBookMarketPrice>
              <span>{`${socketContext.market ? Number(socketContext.market.lastMarketPrice) : 0} ${selectedPair?.QuoteCurrency}`}</span>
            </StyledOrderBookMarketPrice>
            <StyledOrderBookWrapper>
              {
                buy.map((buyOrder, index) => (
                  <StyledOrderBookRow key={index}>
                    <StyledOrderBookPrice style={{color: 'rgba(65, 158, 106, 1)'}}>{buyOrder[0] && Number(buyOrder[0]).toFixed(5)}</StyledOrderBookPrice>
                    <StyledOrderBookAmount>{buyOrder[1] && Number(buyOrder[1]).toFixed(5)}</StyledOrderBookAmount>
                    <StyledOrderBookTotal>{(buyOrder[2]??0).toFixed(2)}</StyledOrderBookTotal>
                    <StyledOrderBookProgress style={{
                      background: 'rgba(65, 158, 106, 0.15)',
                      width: buyOrder[0] && buyOrder[1] ? `${(Number(buyOrder[0]) * Number(buyOrder[1]) / allTotalBuy) * 100}%` : 0
                    }}/>
                  </StyledOrderBookRow>
                ))
              }
            </StyledOrderBookWrapper>
          </>
        )
        }
      </Box>
      
        {/* {
          !socketContext.level2 ?
          <StyledLoadingBook>
            <CircularProgress />
          </StyledLoadingBook> :
          <>
            <Box maxHeight={'50%'} overflow={'auto'}>
              <Table sx={{ width: "100%" }}>
                <TableHead style={{ height: 49 }}>
                  <TableCell align={"left"}>
                    <Typography
                        sx={{ letterSpacing: "1px", color: "#52555E" , paddingLeft: '10px', display: 'flex', justifyContent: 'left', alignItems: 'center'}}
                        variant={"body1"}
                    >
                      Price
                    </Typography>
                  </TableCell>
                  <TableCell align={"center"}>
                    <Typography
                        sx={{ letterSpacing: "1px", color: "#52555E" ,display: 'flex', justifyContent: 'left', alignItems: 'center'}}
                        variant={"body1"}
                    >
                      Amount
                    </Typography>
                  </TableCell>
                  <TableCell align={"right"}>
                    <Typography
                        sx={{ letterSpacing: "1px", color: "#52555E" , paddingRight: '10px', display: 'flex', justifyContent: 'right', alignItems: 'center'}}
                        variant={"body1"}
                    >
                      Total
                    </Typography>
                  </TableCell>
                </TableHead>
                <TableBody>
                    {sell.map((item, i) => (
                        <StyledTableRow key={i}>
                          <StyledTableCellLeft align={"left"}>
                            <Typography
                                sx={{ letterSpacing: "1px", color: "#D83232" , display: 'flex', justifyContent: 'left', alignItems: 'center'}}
                                variant={"body1"}
                            >
                              {item[0] && Number(item[0]).toFixed(5)}
                            </Typography>
                          </StyledTableCellLeft>
                          <TableCell align={"center"}>
                            <Typography
                                sx={{ letterSpacing: "1px", color: "rgba(128, 132, 142, 1)", display: 'flex', justifyContent: 'left', alignItems: 'center' }}
                                variant={"body1"}
                            >
                              {item[1] && Number(item[1]).toFixed(5)}
                            </Typography>
                          </TableCell>
                          <StyledTableCellRight sx={{ letterSpacing: "1px" }} align={"right"}>
                            <Typography
                                sx={{ letterSpacing: "1px", color: "rgba(128, 132, 142, 1)", display: 'flex', justifyContent: 'right', alignItems: 'center' }}
                                variant={"body1"}
                            >
                              {item[0] && item[1] && (Number(item[0]) * Number(item[1])).toFixed(2)}
                            </Typography>
                          </StyledTableCellRight>

                          <StyledBookItemBackground
                              width={getWidthSell(Number(item[1]))}
                              color="rgba(216, 50, 50, 0.15)"
                          />
                        </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>

            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              width={"100%"}
            >
              <Typography
                component={"h4"}
                style={{ margin: "12px 0", color: "#419E6A" }}
                variant={"h4"}
              >
                {!isNaN(computeAveragePriceOfPair) && `${computeAveragePriceOfPair} ${selectedPair?.QuoteCurrency}`}
              </Typography>
            </Box>
            <Box maxHeight={'50%'} overflow={'auto'}>
              <Table>
                <TableBody>
                  {buy.slice(0, 15).map((item, i) => (
                      <StyledTableRow
                          key={i}
                      >
                        <StyledBookItemBackground
                            width={getWidthBuy(Number(item[1]))}
                            color="rgba(65, 158, 106, 0.15)"
                        />
                        <StyledTableCellLeft align={"left"} padding="checkbox">
                          <Typography
                              sx={{ letterSpacing: "1px", color: "#419E6A", display: 'flex', justifyContent: 'left', alignItems: 'center' }}
                              variant={"body1"}
                          >
                            {item[0] && Number(item[0]).toFixed(5)}
                          </Typography>
                        </StyledTableCellLeft>
                        <TableCell align={"center"}>
                          <Typography
                              sx={{ letterSpacing: "1px", color: "rgba(128, 132, 142, 1)"  , display: 'flex', justifyContent: 'left', alignItems: 'center'}}
                              variant={"body1"}
                          >
                            {item[1] && Number(item[1]).toFixed(5)}
                          </Typography>
                        </TableCell>
                        <StyledTableCellRight sx={{ letterSpacing: "1px" }} align={"right"}>
                          <Typography
                              sx={{ letterSpacing: "1px", color: "rgba(128, 132, 142, 1)", display: 'flex', justifyContent: 'right', alignItems: 'center' }}
                              variant={"body1"}
                          >
                            {item[0] && item[1] && (Number(item[0]) * Number(item[1])).toFixed(2)}
                          </Typography>
                        </StyledTableCellRight>
                      </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </>
        } */}
    </StyledBook>
  );
};

const StyledBook = styled(Box)`
  background: #181B25;
  padding: 11px 14px;
  top: 0;
  bottom: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  // min-width: 350px;
`;
