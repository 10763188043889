import "./dashboardStyles.scss";

import { FC, useContext, useEffect, useState } from "react";

import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { useAppDispatch } from "hooks/useAppDispatch";
import { StyledFieldWrapper } from "pages/Wallets/components/CommonComponents.styles";
import { StyledSearchIcon, StyledTextField } from "pages/Wallets/Wallets.styles";
import { CentrifugeContext } from "providers/CentrifugeProvider";
import { Doughnut } from "react-chartjs-2";
import { useNavigate, Link } from "react-router-dom";
import { setSelectedPair, setSelectedPairId } from "store/exchange/exchange.slice";
import { addPairChangingCounter } from "store/exchange/exchange.slice";
import { getCoinPath } from "utils/common.utils";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, Button, InputAdornment, Typography, Skeleton } from "@mui/material";

import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { IWalletBalance } from "../../../types/exchange.types";

ChartJS.register(ArcElement, Tooltip, Legend);
const data = {
	datasets: [
		{
			data: [12, 19, 3, 8],
			backgroundColor: ["#0D0901", "#0157FF", "#00b7f1", "#95B8FD"],
			borderWidth: 0,
		},
	],
};

const OverviewBlock: FC = (): JSX.Element => {
	const { me } = useTypedSelector((state) => state.profile);
	const navigate = useNavigate();
	const walletBalance = useTypedSelector((state) => state.wallets.walletBalance);
	const { currencies } = useTypedSelector((state) => state.exchange);
	const [search, setSearch] = useState<string>("");
	const [walletBalancesList, setWalletBalancesList] = useState<IWalletBalance | null>(null);
	const socketContext = useContext(CentrifugeContext);

	const dispatch = useAppDispatch();
	const router = useNavigate();
	const [chartConfig, setChartConfig] = useState<any>({
		datasets: [
			{
				data: [12, 19, 3, 8],
				backgroundColor: ["##0D0901", "#0157FF", "#00b7f1", "#EFD4A5", "#DADADA"],
				borderWidth: 0,
			},
		],
	});

	const setSelectedPairForTrade = (pair: string) => {
		const pairBody = currencies?.find(
			(currency) => currency.BaseCurrency === pair && currency.QuoteCurrency === "USDT"
		);
		if (pairBody) {
			dispatch(setSelectedPairId(pairBody.ID));
			dispatch(setSelectedPair(pairBody));
			dispatch(addPairChangingCounter());
			router(`/exchange?pair=${pairBody.BaseCurrency}/${pairBody.QuoteCurrency}`);
		}
	};

	useEffect(() => {
		if (walletBalance.data) {
			setChartConfig({
				datasets: [
					{
						data: [
							walletBalance.data.balances[0] ? walletBalance.data.balances[0].balance_eur : 0,
							walletBalance.data.balances[1] ? walletBalance.data.balances[1].balance_eur : 0,
							walletBalance.data.balances[2] ? walletBalance.data.balances[2].balance_eur : 0,
							walletBalance.data.balances[3] ? walletBalance.data.balances[3].balance_eur : 0,
							walletBalance.data.balances[4] ? walletBalance.data.balances[4].balance_eur : 0,
						],
						backgroundColor: ["#0D0901", "#0157FF", "#00b7f1", "#95B8FD", "#DADADA"],
						borderWidth: 0,
					},
				],
			});

			setWalletBalancesList(walletBalance.data);
		}
	}, [walletBalance]);
	//   const sortedWallets = useMemo(() => {
	//     let wallets: ICurrency[] = []

	//     if ((walletsSelector.data && walletsSelector.data.length > 0) && (rates.data)) {
	//         let totalBalance = 0

	//         wallets = [...walletsSelector.data].filter((wallet) => rates.data[wallet.currency])
	//         wallets.sort((a, b) => (b.balance * rates.data[b.currency].EUR) - (a.balance * rates.data[a.currency].EUR))

	//         wallets.forEach((wallet, index) => {
	//             totalBalance += wallet.balance * rates.data[wallet.currency].EUR

	//             if (index <= 4) {
	//                 setChartConfig((prevConfig: any) => ({
	//                     datasets: [
	//                         {
	//                             ...prevConfig.datasets[0],
	//                             data: [...prevConfig.datasets[0].data, wallet.balance * rates.data[wallet.currency].EUR]
	//                         }
	//                     ]
	//                 }))
	//             }
	//         })

	//         setTotalBalance(totalBalance)
	//     }

	//     return wallets
	// }, [walletsSelector, rates])

	useEffect(() => {
		//     if (socketContext.rate && walletBalancesList) {
		//         console.log(socketContext.rate.coin, socketContext.rate.lastPriceEUR)
		//         const tempBalances: any[] = []
		//         walletBalancesList?.balances.forEach(balance => {
		//             if (balance.currency === socketContext.rate?.coin.replace(/\s/g, '')) {
		//                 tempBalances.push({
		//                     ...balance,
		//                     change24h: socketContext.rate?.change24H,
		//                     last_market_price: socketContext.rate?.lastPriceEUR
		//                 })
		//             } else {
		//                 tempBalances.push(balance)
		//             }
		//         })

		//         const newBalance = {
		//             ...walletBalancesList,
		//             balances: tempBalances
		//         }

		//         setWalletBalancesList(newBalance)
		//     }

		if (socketContext.rate !== null && walletBalancesList) {
			const tempBalances: any[] = [];

			if (Array.isArray(socketContext.rate)) {
				const updatedCoins: Record<string, any> = {};

				socketContext.rate.forEach((coin) => {
					updatedCoins[coin.coin.replace(/\s/g, "")] = {
						currency: coin.coin.replace(/\s/g, ""),
						lastPriceEUR: String(coin.lastPriceEUR),
						change24H: String(coin.change24H),
					};
				});

				walletBalancesList?.balances.forEach((balance) => {
					if (updatedCoins[balance.currency]) {
						tempBalances.push({
							...balance,
							lastPriceEUR: updatedCoins[balance.currency].lastPriceEUR,
							change24H: updatedCoins[balance.currency].change24H,
							volume24HEUR: updatedCoins[balance.currency].volume24HEUR,
						});
					} else {
						tempBalances.push(balance);
					}
				});
			} else {
				walletBalancesList?.balances.forEach((balance) => {
					if (socketContext.rate && !Array.isArray(socketContext.rate)) {
						if (balance.currency === socketContext.rate?.coin.replace(/\s/g, "")) {
							tempBalances.push({
								...balance,
								change24h: socketContext.rate?.change24H,
								last_market_price: socketContext.rate?.lastPriceEUR,
							});
						} else {
							tempBalances.push(balance);
						}
					}
				});
			}

			const newBalance = {
				...walletBalancesList,
				balances: tempBalances,
			};

			setWalletBalancesList(newBalance);
		}
	}, [socketContext.rate]);

	const getFormattedPrice = (price: string | number) => {
		const fmtPrice = String(price).slice(0, 8);
		return Number(fmtPrice);
	};

	const computedBalances = (() => {
		if (walletBalancesList && !walletBalancesList.balances) return [];
		if (!search) return walletBalancesList?.balances;
		return walletBalancesList?.balances.filter((balance) =>
			balance.currency?.toLowerCase().includes(search.toLowerCase())
		);
	})();

	return (
		<div className='background mb20 paddingBig overviewBlock welcome'>
			<Box display={"flex"} justifyContent={"left"} alignItems={"center"}>
				<div className='overview-block__header__name'>
					<span className={"web21"}>Welcome, </span>
					{me && me?.user.first_name ? (
						<span className={"web21"} style={{ color: "#00b7f1" }}>
							{me && me?.user.first_name}
						</span>
					) : (
						<Skeleton />
					)}
				</div>
				<Button
					size={"small"}
					className='no-hover'
					onClick={() => navigate("/dashboard/deposit")}
					sx={{
						color: "#00b7f1",
						minWidth: 100,
						height: "fit-content",
						ml: 53 / 8,
						border: "1.5px solid #00b7f1",
						fontWeight: 400,
					}}>
					<span className={"web10-2 default-case"}>Deposit</span>
				</Button>
			</Box>
			<div className='subtitleBlock'>
				<Box display={"flex"} alignItems={"center"}>
					<div>
						<Typography variant={"h5"} color={"#80848E"}>
							Total balance
						</Typography>
						{walletBalance.data && walletBalance.data.total !== null ? (
							<p>
								<span className={"web25"}>
									{walletBalance.data
										? Number(walletBalance.data.total).toLocaleString("en-US", {
												style: "currency",
												currency: "EUR",
										  })
										: (0.0).toLocaleString("en-US", { style: "currency", currency: "EUR" })}
								</span>
							</p>
						) : (
							<Skeleton />
						)}
					</div>
					<Box ml={9}>
						<Typography variant={"h5"} color={"#80848E"}>
							24h change
						</Typography>
						<p
							className={`${
								walletBalance.data && walletBalance.data.change24h > 0
									? "subtitleBlock_percentage"
									: "subtitleBlock_negative"
							}`}>
							{walletBalance.data && walletBalance.data.change24h !== null ? (
								<>
									<span className={"web25"}>
										{walletBalance.data && walletBalance.data.change24h.toFixed(2)}
									</span>
									<span className={"web25-l"}>%</span>
								</>
							) : (
								<Skeleton />
							)}
						</p>
					</Box>
				</Box>

				<div className='doughnut'>
					<Doughnut
						data={chartConfig}
						width={130}
						height={130}
						options={{
							cutout: "80%",
							responsive: true,
							maintainAspectRatio: true,
						}}
					/>
				</div>
			</div>
			<Link to='/markets'>
				<Typography
					variant={"h6"}
					color={"black"}
					sx={{ mb: 3, display: "inline-flex", alignItems: "center" }}>
					Markets
					<ChevronRightIcon sx={{ width: 17, height: 20, marginLeft: 0.5, color: "#00b7f1" }} />
				</Typography>
			</Link>
			<StyledFieldWrapper>
				<StyledTextField
					id='input-with-icon-textfield'
					style={{ marginTop: 10 }}
					onChange={(e) => setSearch(e.target.value)}
					startAdornment={
						<InputAdornment position='start'>
							<StyledSearchIcon />
						</InputAdornment>
					}
					placeholder='Search for coin'
				/>
			</StyledFieldWrapper>

			<div className='tableBlock'>
				<div>
					<div className='tableBlock_grid'>
						<p className='tableBlock_th'>Coin</p>
						<p className='tableBlock_th'>In portfolio (€)</p>
						<p className='tableBlock_th'>Price</p>
						<p className='tableBlock_th textAlignRight'>24h change</p>
					</div>
					{/* <div className="tableBlock_grid">
                        <div className="tableBlock_td d-inline-flex align-center">
                            <div className={'crypto d-inline-flex align-center'}>
                                <img src="/images/coins/bitcoin.svg" alt="Bitcoin"/>
                                <span className={'crypto__name'}>BTC/</span>
                            </div>
                            <span className="currency">USDC</span>
                        </div>
                        <p className="tableBlock_td">€2.567</p>
                        <p className="tableBlock_td">€2.567</p>
                        <p className="tableBlock_td green textAlignRight">
                            +3.5%
                        </p>
                        <Button variant={'text'}>
                            <Typography variant={'caption'}
                                        sx={{textTransform: 'initial !important', color: '#00B7F1'}}>
                                Trade
                            </Typography>
                        </Button>
                    </div> */}
					{/* <pre>{JSON.stringify(walletBalancesList, null, 2)}</pre> */}
					{computedBalances ? (
						computedBalances.map((wallet, index) => (
							<div key={index} className='tableBlock_grid'>
								<div className='tableBlock_td d-inline-flex align-center'>
									<div className={"crypto d-inline-flex align-center"}>
										<img
											src={`/images/coins/${getCoinPath(wallet.currency)}`}
											alt={wallet.currency}
										/>
										<span
											className={index < 5 ? `crypto__name crypto__name-priority-${index}` : ""}>
											{wallet.currency}
										</span>
									</div>
								</div>
								{/* toLocaleString('en-US', {style:'currency', currency:'EUR'}) */}
								<p className='tableBlock_td'>
									{wallet.balance_eur.toLocaleString("en-US", {
										style: "currency",
										currency: "EUR",
									})}
								</p>
								<p className='tableBlock_td'>{`${
									wallet.lastPriceEUR
										? `€${getFormattedPrice(wallet.lastPriceEUR)}`
										: `€${getFormattedPrice(wallet.last_market_price)}`
								}`}</p>
								<p
									className={`tableBlock_td textAlignRight ${
										wallet.change24h == null || wallet.change24h <= 0 ? "red" : "green"
									}`}>
									{wallet.change24h ? `${Number(wallet.change24h).toFixed(2)}%` : "0%"}
								</p>
								<Button variant={"text"} onClick={() => setSelectedPairForTrade(wallet.currency)}>
									<Typography
										variant={"caption"}
										sx={{ textTransform: "initial !important", color: "#00b7f1" }}>
										Trade
									</Typography>
								</Button>
							</div>
						))
					) : (
						<div>loading</div>
					)}

					{/* <div className="tableBlock_grid">
                        <div className="tableBlock_td d-inline-flex align-center">
                            <div className={'crypto d-inline-flex align-center'}>
                                <img src="/images/coins/ETH.svg" alt="Eth"/>
                                <span className={'crypto__name'}>ETH/</span>
                            </div>
                            <span className="currency">USDC</span>
                        </div>
                        <p className="tableBlock_td">€2.567</p>
                        <p className="tableBlock_td">€2.567</p>
                        <p className="tableBlock_td red textAlignRight">
                            -3.5%
                        </p>
                        <Button variant={'text'}>
                            <Typography variant={'caption'} onClick={() => setSelectedPairForTrade(wallet.currency)}
                                        sx={{textTransform: 'initial !important', color: '#00B7F1'}}>
                                Trade
                            </Typography>
                        </Button>
                    </div> */}
				</div>
			</div>
		</div>
	);
};

export default OverviewBlock;
