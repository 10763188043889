import { ChangeEvent, FC, useEffect, useState } from "react";

import { AdditionalLayout } from "components";
import InputMask from "react-input-mask";
import { NumericFormat } from "react-number-format";
import { NavLink } from "react-router-dom";

import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Grid, Input } from "@mui/material";
import Box from "@mui/material/Box";

import { bankApi, IPaymentReq } from "../../api/bank.api";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { getBalanceThunk } from "../../store/wallets/wallets.thunk";
import {
	CardNumberBlock,
	StyledBalanceTitle,
	StyledBalanceValue,
	StyledBalanceValueCurrency,
	StyledButton,
	StyledButtonLink,
	StyledCancelIcon,
	StyledCheckCircleIcon,
	StyledGridContainer,
	StyledGridItemPlug,
	StyledGridItemPlugMain,
	StyledGridItemTitle,
	StyledPaymentMethodtitle,
	StyledSpepsText,
	StyledVerificationItem,
	WorkItem,
	WorkItemText,
	WorkItemTitle,
} from "./Withdraw.style";

const Withdraw: FC = (): JSX.Element | null => {
	const dispatch = useAppDispatch();
	const { data } = useTypedSelector((state) => state.wallets.balance);
	const [paymentInfo, setPaymentInfo] = useState<IPaymentReq>({
		amount: 0,
		currency: "",
	});
	const [errors, setErrors] = useState<string>();

	useEffect(() => {
		dispatch(getBalanceThunk());
	}, []);

	const paymentHandler = async () => {
		bankApi
			.createPayment(paymentInfo)
			.then((res) => window.open(res.redirect))
			.catch((err) => console.log("oops"));
	};

	const currencyCheckHandler = (event: ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setPaymentInfo({ ...paymentInfo, currency: value });
	};

	const amountHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { value } = event.target;
		if (value.trim() === "" || !value) {
			setErrors("Amount is under zero or no amount is provided!");
			return;
		}
		setPaymentInfo({ ...paymentInfo, amount: +value });
		setErrors("");
	};
	const cardNumberInput = () => {};

	const { data: balance } = useTypedSelector((state) => state.wallets.balance);
	const verification = useTypedSelector((state) => state.profile.me?.verification);
	return (
		<AdditionalLayout>
			<StyledGridContainer container justifyContent='space-between'>
				<Grid item xs={7.5}>
					<StyledGridItemPlugMain>
						<StyledGridItemTitle container justifyContent='space-between' alignItems='center'>
							<Grid xs={4} item></Grid>
							<Grid xs={4} item>
								Withdraw Euro
							</Grid>
							<Grid xs={4} item>
								<NavLink to='/dashboard/deposit'>
									<StyledButtonLink endIcon={<ArrowRightAltIcon />} variant='text'>
										Deposit Fiat
									</StyledButtonLink>
								</NavLink>
							</Grid>
						</StyledGridItemTitle>
						<StyledBalanceTitle>Total balance</StyledBalanceTitle>
						<StyledBalanceValue>
							<StyledBalanceValueCurrency>€</StyledBalanceValueCurrency>
							{balance === 0 ? "0.00" : balance?.toLocaleString("en-EN")}{" "}
							{/** TODO add dynamic locale */}
						</StyledBalanceValue>

						<Box display='flex' justifyContent='center'>
							<NumericFormat
								decimalScale={2}
								decimalSeparator=','
								fixedDecimalScale
								placeholder='€ 0,00'
								prefix='€ '
								thousandSeparator='.'
								style={{ color: "#000000", fontSize: "16px !important", width: "416px" }}
								customInput={Input}
								onChange={(e) => amountHandler(e)}
							/>
							{/*  */}
						</Box>
						<Box
							display='flex'
							justifyContent='left'
							flexDirection='column'
							style={{ marginLeft: "122px" }}>
							<StyledPaymentMethodtitle>Card details</StyledPaymentMethodtitle>
							<CardNumberBlock>
								<InputMask
									mask='9999 9999 9999 9999'
									placeholder='Card number'
									style={{
										width: 349,
										border: "1px solid #E5E7EB",
										borderRadius: "16px",
										background: "#F9FAFB",
										padding: "13px 20px",
										marginRight: "22px",
									}}
									onChange={cardNumberInput}
								/>
								<img style={{ marginRight: "18px" }} src={`/images/payments/visa.svg`} alt='visa' />
								<img src={`/images/payments/mastercard.svg`} alt='visa' />
							</CardNumberBlock>
							<InputMask
								mask='99/99'
								type='custom'
								placeholder='Expiration date'
								style={{
									width: 162,
									border: "1px solid #E5E7EB",
									borderRadius: "16px",
									background: "#F9FAFB",
									padding: "13px 20px",
									marginTop: "27px",
								}}
							/>
						</Box>
						<Box />
						{/* <StyledInputDateBRadius pattern="[0-9.]+" type="text" maxLength={4}  placeholder='Expiration date'/> */}
						<Box display='flex' justifyContent='center'>
							<StyledButton disabled variant='outlined' onClick={() => paymentHandler()}>
								Continue
							</StyledButton>
						</Box>
					</StyledGridItemPlugMain>
				</Grid>

				<Grid item xs={4}>
					<StyledGridItemPlug>
						<StyledGridItemTitle>Verification steps</StyledGridItemTitle>
						<StyledSpepsText>
							The following steps are required before being able to deposit Fiat (€)
						</StyledSpepsText>

						<StyledVerificationItem
							// display="flex"
							// alignItems="center"
							// justifyContent="space-between"
							container>
							<Grid item xs={7}>
								Email address
							</Grid>
							<Grid item xs={5} display='flex' alignItems='center'>
								{verification?.email ? <StyledCheckCircleIcon /> : <StyledCancelIcon />}
								{verification?.email ? "Verified" : "Not verified"}
							</Grid>
						</StyledVerificationItem>

						<StyledVerificationItem
							display='flex'
							alignItems='center'
							justifyContent='space-between'>
							<Grid item xs={7}>
								Phone number
							</Grid>
							<Grid item xs={5} display='flex' alignItems='center'>
								{verification?.phone ? <StyledCheckCircleIcon /> : <StyledCancelIcon />}
								{verification?.phone ? "Verified" : "Not verified"}
							</Grid>
						</StyledVerificationItem>

						<StyledVerificationItem
							display='flex'
							alignItems='center'
							justifyContent='space-between'>
							<Grid item xs={7}>
								Identity document
							</Grid>
							<Grid item xs={5} display='flex' alignItems='center'>
								{verification?.kyc ? <StyledCheckCircleIcon /> : <StyledCancelIcon />}
								{verification?.kyc ? "Verified" : "Not verified"}
							</Grid>
						</StyledVerificationItem>

						<StyledVerificationItem
							display='flex'
							alignItems='center'
							justifyContent='space-between'>
							<Grid item xs={7}>
								Address
							</Grid>
							<Grid item xs={5} display='flex' alignItems='center'>
								{verification?.kyc ? <StyledCheckCircleIcon /> : <StyledCancelIcon />}
								{verification?.kyc ? "Filled in" : "Not filled in"}
							</Grid>
						</StyledVerificationItem>
					</StyledGridItemPlug>

					<StyledGridItemPlug>
						<StyledGridItemTitle>How it works</StyledGridItemTitle>

						<WorkItem>
							<img src='/images/works/works1.svg' alt='Select payment method' />
							<WorkItemTitle>Select payment method</WorkItemTitle>
							<WorkItemText>Enter amount and choose your preferred method of payment</WorkItemText>
						</WorkItem>

						<WorkItem>
							<img src='/images/works/works2.svg' alt='Transfer credit to your BitTrade wallet' />
							<WorkItemTitle>Transfer credit to your BitTrade wallet</WorkItemTitle>
							<WorkItemText>Enter amount and choose your preferred method of payment</WorkItemText>
						</WorkItem>

						<WorkItem>
							<img src='/images/works/works3.svg' alt='Select payment method' />
							<WorkItemTitle>Trade the future</WorkItemTitle>
							<WorkItemText>Enter amount and choose your preferred method of payment</WorkItemText>
						</WorkItem>
					</StyledGridItemPlug>
				</Grid>
			</StyledGridContainer>
		</AdditionalLayout>
	);
};

export default Withdraw;
