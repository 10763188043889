import "./Checkbox.style.scss";

import { FC, useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";

import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import {
	Box,
	Button,
	FormControl,
	FormControlLabel,
	FormLabel,
	InputLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	Typography,
} from "@mui/material";

import { useTypedSelector } from "../../hooks/useTypedSelector";
import { IConfirmUserCreate, IStatusConfirmation } from "../../types/confirmation.types";
import { ICountry } from "../../types/profile.types";
import { BFileUpload } from "../UI/molecules/BFileUpload/BFileUpload";

interface IProps {
	countries: ICountry[];
	onSubmit: (data: FormData) => void;
	statusHandler: (data: IStatusConfirmation) => any;
}

const initialValues: IConfirmUserCreate = {
	doc_type: "PASSPORT",
	country: "",
	files: [],
};

const validationSchemaRaw = yup.object({
	doc_type: yup.string().required("This Field is required"),
	country: yup.string().required("This Field is required"),
	files: yup
		.array()
		.of(yup.mixed().required("File is required"))
		.min(1, "You need 1 file for Passport"),
});

export const IdentifyDocumentForm: FC<IProps> = ({ countries, onSubmit, statusHandler }) => {
	const [validationSchema, setSchema] = useState<any>(validationSchemaRaw);
	const [fileTypeError, setFileTypeError] = useState<boolean>(false);

	const nav = useNavigate();
	const { success } = useTypedSelector((state) => state.dashboard.confirmation);

	useEffect(() => {
		if (success !== null && success) {
			nav("/dashboard/verification");
		}
	}, [success]);

	const { setFieldValue, handleChange, handleSubmit, values, errors, validateForm } =
		useFormik<IConfirmUserCreate>({
			initialValues,
			validationSchema,
			onSubmit(data) {
				const formData = new FormData();
				formData.append("doc_type", data.doc_type);
				formData.append("country", data.country as string);
				data.files.forEach((file: File) => {
					formData.append("files", file);
				});
				onSubmit(formData);
				statusHandler("default");
			},
		});

	useLayoutEffect(() => {
		if (values.doc_type === "ID_CARD") {
			setSchema(
				yup.object({
					doc_type: yup.string().required("This Field is required"),
					country: yup.string().required("This Field is required"),
					files: yup
						.array()
						.of(yup.mixed().required("File is required"))
						.min(2, "You need 2 files for ID card"),
				})
			);
		} else {
			setSchema(validationSchemaRaw);
		}
	}, [values.doc_type]);

	useEffect(() => {
		setFieldValue("files", []);
		validateForm();
	}, [validationSchema]);

	const computedDisabledUpload = useMemo(() => {
		if (values.doc_type === "PASSPORT" && values.files.length === 1) return true;
		if (values.doc_type === "ID_CARD" && values.files.length === 2) return true;
		return false;
	}, [values.doc_type, values.files]);

	const handleChangeFile = useCallback(
		(val: any[]) => {
			if (val.length) {
				const fileType = val[0].name.split(".")[val[0].name.split(".").length - 1];
				if (fileType === "jpg" || fileType === "jpeg" || fileType === "png") {
					setFileTypeError(false);
					setFieldValue("files", val);
				} else {
					setFileTypeError(true);
				}
			} else {
				setFileTypeError(false);
				setFieldValue("files", val);
			}
		},
		[values]
	);
	const newIcon = (
		<svg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<g clip-path='url(#clip0_38_56389)'>
				<path
					d='M10.4375 6.875L6.4375 10.875L2.4375 6.875'
					stroke='#01C2FF'
					stroke-width='1.5'
					strokeLinecap='square'
					stroke-linejoin='round'
				/>
			</g>
			<defs>
				<clipPath id='clip0_38_56389'>
					<rect width='13' height='13' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);

	return (
		<Box component={"form"} onSubmit={handleSubmit}>
			{/*Select Country*/}
			<Typography sx={{ mb: 5 }} style={{ color: "black" }} variant={"h4"}>
				Select a country that issued the document
			</Typography>
			<FormControl variant={"standard"} fullWidth>
				<InputLabel id={"country"}>Country</InputLabel>
				<Select
					IconComponent={() => <div>{newIcon}</div>}
					name={"country"}
					style={{ color: "black", width: 452 }}
					onChange={handleChange}>
					{countries.map((item, index) => (
						<MenuItem key={index} value={item.code3}>
							{item.name}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			{errors.country && (
				<Typography style={{ marginTop: "8px" }} color='red'>
					{errors.country}
				</Typography>
			)}
			{/*Document Type*/}
			<FormControl style={{ color: "black" }} sx={{ mt: 8 }} fullWidth>
				<FormLabel id='doc_type' sx={{ color: "black" }}>
					<Typography style={{ color: "black" }} variant={"h4"}>
						Choose your document type
					</Typography>
				</FormLabel>
				<RadioGroup
					onChange={handleChange}
					row
					aria-labelledby='doc_type'
					defaultValue='PASSPORT'
					name='doc_type'>
					<FormControlLabel
						style={{ color: "black", fontSize: "16px" }}
						value='PASSPORT'
						control={<Radio />}
						label={<Typography style={{ color: "black", fontSize: "16px" }}>Passport</Typography>}
					/>
					<FormControlLabel
						style={{ color: "black" }}
						value='ID_CARD'
						control={<Radio />}
						label={<Typography style={{ color: "black" }}>ID Card</Typography>}
					/>
				</RadioGroup>
			</FormControl>

			{/*    Photo of Document*/}

			<Box mt={7}>
				<Typography variant={"h4"} style={{ color: "black" }}>
					Take a photo of your passport
				</Typography>
				<ul style={{ marginBottom: 17 }}>
					<li>
						<Typography style={{ color: "black" }} variant={"h5"}>
							Bright and clear
						</Typography>
					</li>
					<li>
						<Typography style={{ color: "black" }} variant={"h5"}>
							Uncut
						</Typography>
					</li>
				</ul>
				<Box display={"flex"} gap={2}>
					<img src='/images/verification/Group 13389.png' alt='passport correct' loading={"lazy"} />
					<img src='/images/verification/Group 13388.png' alt='passport invalid' loading={"lazy"} />
					<img
						src='/images/verification/Group 13387.png'
						alt='passport invalid 1'
						loading={"lazy"}
					/>
				</Box>

				<Box mt={6}>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							flexDirection: "column",
							color: "#80848E",
							fontFamily: "Poppins",
							fontSize: "14px",
						}}>
						<span>Upload the photo of the document</span>
						<span>You can only upload png, jpeg, jpg</span>
					</div>
					<BFileUpload
						disabled={computedDisabledUpload}
						error={
							(fileTypeError ? "You can only upload png, jpeg, jpg" : (errors.files as string)) ||
							""
						}
						files={values.files}
						label={""}
						onChange={handleChangeFile}
					/>
				</Box>
			</Box>
			<Box display={"flex"} justifyContent={"center"} alignItems={"center"} width={"100%"}>
				<Button
					style={{
						color: fileTypeError || success === true ? "#80848E" : "#00b7f1",
						textTransform: "initial",
					}}
					type={"submit"}
					sx={{
						mt: 5,
						border: "1.5px solid #00b7f1",
						"&:hover": {
							border: "1.5px solid #00b7f1",
						},
					}}
					size={"large"}
					variant={"outlined"}
					disabled={fileTypeError || success === true}>
					Send
				</Button>
			</Box>
		</Box>
	);
};
