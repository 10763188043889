import styled from '@emotion/styled';
import {
  TableCell,
  TableRow,
} from '@mui/material';

export const StyledTitle = styled("h3")(({ theme }) => ({
    fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "22px",
    lineHeight: "33px",
    marginBottom: 41,
    marginTop: 0,
    padding:0
  }));

  export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    
    padding: "15px 0",
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    color: '#000000',
  }));
  export const StyledTableCellFiat = styled(TableCell)(({ theme }) => ({
    padding: "15px 0",
    fontFamily: 'Poppins',
    width: "auto",
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    color: '#000000',

  }));

  export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    padding: "15px 0",
    color: "#000000",
    borderBottom: "1px solid #DADADA",
    background: "#fff"
  }));
  export const StyledType = styled("span")(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "20px",
    color: "#000000",
    display: "block",
    marginBottom: 7,
    marginTop: 34
  }));
  