import SearchIcon from "@mui/icons-material/Search";
import { Button, Input, styled } from "@mui/material";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export const StyledTitle = styled(Box)(({ theme }) => ({
	textAlign: "center",
	width: "100%",
	// marginTop: 50,
	marginBottom: 73,
	fontSize: 20,
	fontWeight: 600,
}));

export const StyledButton = styled(Button)(({ theme }) => ({
	width: 130,
	height: 33,
}));

export const StyledImg = styled("img")(({ theme }) => ({
	width: "100%",
}));

export const StyledCoinIcon = styled("img")(() => ({
	width: "30px",
}));

export const StyledTableCellHeadText = styled("div")(({ theme }) => ({
	color: "#80848E",
	fontSize: 16,
}));

export const StyledTextField = styled(Input)(({ theme }) => ({
	width: "420px",
	color: "#fff !important",
	fontFamily: "Poppins !important",
	fontSize: "14px !important",
	borderBottom: "1px solid #FFFFFF",
	"& input::placeholder": {
		color: "#FFFFFF",
	},
	"& svg": {
		color: "#FFFFFF",
	},
}));

export const StyledSearchIcon = styled(SearchIcon)(({ theme }) => ({
	color: "black",
}));

export const StyledWrapper = styled("div")(({ theme }) => ({
	background: "#FFFFFF",
	color: "#000000",
	paddingTop: 50,
	minHeight: "calc(100vh - 81px)",
	maxHeight: " calc(100vh - 0)",
	paddingBottom: "65px",
	overflow: "auto",
	display: "grid",
	gridTemplatecolumns: "2fr 1fr",
}));

export const StyledSubWrapper = styled("div")(({ theme }) => ({
	// background: "#FFFFFF",
	paddingTop: 50,
	// minHeight: "calc(100vh - 81px)",
}));

export const StyledMarketsItem = styled(Box)(({ theme }) => ({
	border: "1px solid #F5F5F5",
	padding: 30,
}));

export const StyledMarketItemName = styled("span")(({ theme }) => ({
	marginLeft: 16,
}));

export const StyledMarketItemPercent = styled("span")(({ theme }) => ({
	color: "#008B06",
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
	// borderTop: "2px solid #F5F5F5",
	// borderBottom: "2px solid #F5F5F5",
	padding: "20px 0",
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
	padding: "20px 0",
	color: "#FFFFFF",
}));

export const StyledTableCellThin = styled(TableCell)(({ theme }) => ({
	padding: "20px 0",
	width: 200,
	color: "#FFFFFF",
}));

export const StyledPercent = styled("span")<{ isPositive: boolean }>(({ isPositive }) => ({
	color: isPositive ? "#008B06" : "#DD0000",
}));

export const StyledShortName = styled("span")(() => ({
	color: "#80848E",
}));
