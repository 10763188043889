import { FC, useEffect, useState } from "react";

import { AdditionalLayout } from "components";
import { SuccessCopy } from "pages/Affiliates/components/SuccessCopy";
import { NavLink } from "react-router-dom";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import EastIcon from "@mui/icons-material/East";
import { Button, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";

import { WalletQrModal } from "../../../../components/Modals/WalletQrModal";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { useModalManager } from "../../../../hooks/useModalManager";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { getWalletsThunk } from "../../../../store/wallets/wallets.thunk";
import { EModalId } from "../../../../types/common.types";
import { ICurrency } from "../../../../types/exchange.types";
import {
	StyledAddress,
	StyledAddressTitle,
	StyledCurrencySmallImage,
	StyledCurrencyValue,
	StyledCurrencyWrapper,
	StyledDialog,
	StyledDialogTitle,
	StyledGridContainer,
	StyledGridItem,
	StyledGridItemTitle,
	StyledInputSearch,
	StyledMainWrapper,
	StyledNetworkText,
	StyledNetworkWrapper,
	StyledSearchIcon,
	StyledSelectSubWrapper,
	StyledSelectTitle,
	StyledSelectValue,
	StyledSelectWrapper,
	StyledWarningAmberRoundedIcon,
	StyledWarningText,
	StyledWarningWrapper,
	StyledWrapper,
	WorkItem,
	WorkItemText,
	WorkItemTitle,
} from "../CommonComponents.styles";
import { networks } from "../constants";

const Deposit: FC = (): JSX.Element | null => {
	const [initialWallet, setInitialWallet] = useState<boolean>(true);
	const wallets = useTypedSelector((state) => state.wallets.wallets);
	const selectedDepositWallet = useTypedSelector((state) => state.wallets.selectedDepositWallet);
	const [currency, setCurrency] = useState<ICurrency | null>(null);
	const [showNetwork, setShowNetwork] = useState<boolean>(false);
	const [showAddress, setShowAddress] = useState<boolean>(false);

	const [network, setNetwork] = useState(networks[0]);
	const [search, setSearch] = useState<string>("");
	const [openCurrency, setOpenCurrency] = useState<boolean>(false);
	const [openNetwork, setOpenNetwork] = useState<boolean>(false);
	const [showSuccess, setShowSuccess] = useState(false);
	const dispatch = useAppDispatch();

	const handleSelectCurrency = (currency: any) => {
		setCurrency(currency);
		setOpenCurrency(false);
		setShowSuccess(false);
		setShowNetwork(true);
		setShowAddress(true);
		setNetwork(networks.find((network) => network.value === currency.network) || network);
	};

	const handleSelectNetwork = (network: any) => {
		setNetwork(network);
		setShowSuccess(false);
		setOpenNetwork(false);
		setShowAddress(true);
	};

	const handleCopy = (textToClipboard: string) => {
		// const textToClipboard: string = text
		const tmpTextArea = document.createElement("textarea");
		setShowSuccess(true);
		tmpTextArea.value = textToClipboard;
		document.body.appendChild(tmpTextArea);
		tmpTextArea.select();
		document.execCommand("copy");
		document.body.removeChild(tmpTextArea);
	};

	useEffect(() => {
		dispatch(getWalletsThunk());
	}, []);

	useEffect(() => {
		if (wallets.data && !selectedDepositWallet) {
			const initialCurrency = wallets.data.filter((wallet) => wallet.currency === "USDT");

			if (initialCurrency.length) {
				setCurrency(initialCurrency[0]);
			}
		}
	}, [wallets]);

	const computedMarkets = (() => {
		if (!wallets.data) return [];
		if (!search) return wallets.data;
		return wallets?.data.filter(
			(market) =>
				market.currency?.toLowerCase().includes(search.toLowerCase()) ||
				market.currency_full?.toLowerCase().includes(search.toLowerCase())
		);
	})();
	const resWithoutEur = computedMarkets.filter((item) => item.currency !== "EUR");
	const { setModalId } = useModalManager();
	const handleQrModal = (open: boolean) => () => {
		setModalId(open ? EModalId.walletQr : null);
	};

	const modalCloseHandler = () => {
		setSearch("");
		setOpenCurrency(false);
	};

	useEffect(() => {
		if (computedMarkets.length) {
			if (initialWallet) {
				if (selectedDepositWallet !== null) {
					const wallet = computedMarkets.find(
						(market) => market.currency === selectedDepositWallet
					);

					if (wallet) {
						handleSelectCurrency(wallet);
					}

					setInitialWallet(false);
				}
			}
		}
	}, [computedMarkets]);

	return (
		<AdditionalLayout>
			<StyledGridContainer
				container
				style={{
					maxHeight: "calc(100vh - 0)",
					overflow: " auto",
					background: "#F5F5F5",
					display: "flex",
					padding: "20px 32px 56px 0px",
					gap: "20px",
				}}
				justifyContent='space-between'>
				<StyledGridItem item xs={7.5}>
					<StyledMainWrapper>
						<StyledGridItemTitle>Deposit Crypto</StyledGridItemTitle>

						<StyledSelectWrapper>
							<StyledSelectTitle>Select coin</StyledSelectTitle>
							<StyledSelectSubWrapper
								display='flex'
								justifyContent='space-between'
								alignItems='center'
								onClick={setOpenCurrency.bind(null, true)}>
								<Box display='flex' alignItems='center'>
									<StyledCurrencySmallImage src={`/images/coins/${currency?.currency}.svg`} />
									<div>{currency?.currency}</div>
								</Box>

								<ArrowDropDownIcon />
							</StyledSelectSubWrapper>
						</StyledSelectWrapper>
						{showNetwork ? (
							<StyledSelectWrapper>
								<StyledSelectTitle>Select network</StyledSelectTitle>
								<StyledSelectSubWrapper
									display='flex'
									justifyContent='space-between'
									alignItems='center'
									className='networkSelect'
									// onClick={setOpenNetwork.bind(null, true)}
								>
									<Box display='flex' alignItems='center'>
										<div>
											<StyledSelectValue>{network.value}</StyledSelectValue> {network.label}
										</div>
									</Box>

									<ArrowDropDownIcon />
								</StyledSelectSubWrapper>
							</StyledSelectWrapper>
						) : null}

						{showAddress && (
							<>
								<StyledAddressTitle>{currency?.currency} address</StyledAddressTitle>
								<div style={{ display: "flex", gap: 20 }}>
									{" "}
									<StyledAddress>{currency?.address}</StyledAddress>
									{showSuccess && <SuccessCopy text='address' />}
								</div>
								<Box display='flex' justifyContent='space-between'>
									<Button
										style={{
											color: "#80848E",
											textTransform: "capitalize",
											border: "1.5px solid #80848E",
											width: 169,
											height: 36,
											padding: 0,
										}}
										startIcon={
											<img
												// className="affiliates__bg"
												src='/images/copyIcon.svg'
												alt='dashboard background'
												width={20}
												height={20}
											/>
										}
										variant='outlined'
										onClick={handleCopy.bind(null, currency?.address as string)}>
										Copy Address
									</Button>
									<Button
										startIcon={
											<img
												// className="affiliates__bg"
												src='/images/qr-blue.svg'
												alt='dashboard background'
												width={20}
												height={20}
											/>
										}
										variant='outlined'
										style={{
											color: "#80848E",
											textTransform: "capitalize",
											border: "1.5px solid #80848E",
											width: 169,
											height: 36,
											padding: 0,
										}}
										onClick={handleQrModal(true)}>
										Show QR Code
									</Button>
								</Box>
							</>
						)}

						<StyledWarningWrapper display='flex' justifyContent='center' alignItems='flex-start'>
							<StyledWarningAmberRoundedIcon />
							<div>
								<div>Only send {currency?.currency} to this address!</div>
								<StyledWarningText>
									Make sure to only deposit {currency?.currency}, or else your funds will be lost.
								</StyledWarningText>
							</div>
						</StyledWarningWrapper>
					</StyledMainWrapper>
				</StyledGridItem>

				<Grid item xs={4}>
					<Box display='flex' justifyContent='center'>
						<NavLink to='/dashboard/deposit'>
							<Button
								style={{
									border: "1.5px solid #00b7f1",
									fontWeight: "400",
									fontSize: "16px",
									textTransform: "capitalize",
									width: 228,
									height: 49,
									padding: 0,
									color: "#00b7f1",
								}}
								variant='outlined'
								endIcon={<EastIcon />}>
								Deposit Fiat
							</Button>
						</NavLink>
					</Box>

					<StyledWrapper>
						<StyledGridItemTitle>How it works</StyledGridItemTitle>

						<WorkItem>
							<img src='/images/works/works1.svg' alt='Select payment method' />
							<WorkItemTitle>Select payment method</WorkItemTitle>
							<WorkItemText>Enter amount and choose your preffered method of payment</WorkItemText>
						</WorkItem>

						<WorkItem>
							<img src='/images/works/works2.svg' alt='Transfer credit to your BitTrade wallet' />
							<WorkItemTitle>Transfer credit to your BitTrade wallet</WorkItemTitle>
							<WorkItemText>Enter amount and choose your preffered method of payment</WorkItemText>
						</WorkItem>

						<WorkItem>
							<img src='/images/works/works3.svg' alt='Select payment method' />
							<WorkItemTitle>Trade the future</WorkItemTitle>
							<WorkItemText>Enter amount and choose your preffered method of payment</WorkItemText>
						</WorkItem>
					</StyledWrapper>
				</Grid>

				<StyledDialog open={openCurrency} onClose={modalCloseHandler}>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<StyledDialogTitle>Select crypto to deposit</StyledDialogTitle>
						<CloseIcon
							onClick={modalCloseHandler}
							style={{ position: "absolute", right: 12, cursor: "pointer", top: 12 }}
						/>
					</div>
					<FormControl variant='standard'>
						<StyledInputSearch
							onChange={(e) => setSearch(e.target.value)}
							style={{ color: "#000", fontSize: "14px", lineHeight: "21px" }}
							id='input-with-icon-adornment'
							placeholder='Search crypto name'
							startAdornment={
								<InputAdornment position='start'>
									<StyledSearchIcon />
								</InputAdornment>
							}
						/>
					</FormControl>

					{computedMarkets &&
						resWithoutEur.map((item) => {
							return (
								<StyledCurrencyWrapper
									key={item.currency}
									onClick={handleSelectCurrency.bind(null, item)}
									display='flex'
									alignItems='center'>
									<StyledCurrencySmallImage src={`/images/coins/${item.currency}.svg`} />
									<span>
										{item.currency_full}{" "}
										<span style={{ color: "#80848E", fontWeight: 500 }}>{item.currency}</span>
									</span>
								</StyledCurrencyWrapper>
							);
						})}
				</StyledDialog>

				<StyledDialog open={openNetwork} onClose={setOpenNetwork.bind(null, false)}>
					<StyledDialogTitle>Select network</StyledDialogTitle>

					<StyledNetworkText>
						Make sure to select the right network to prevent assets from becoming lost!
					</StyledNetworkText>

					{networks.map((item) => (
						<StyledNetworkWrapper key={item.value} onClick={handleSelectNetwork.bind(null, item)}>
							<div>{item.value}</div>
							<StyledCurrencyValue>{item.label}</StyledCurrencyValue>
						</StyledNetworkWrapper>
					))}
				</StyledDialog>

				{currency && currency.address && (
					<WalletQrModal coin={currency?.currency} address={currency.address} />
				)}
			</StyledGridContainer>
		</AdditionalLayout>
	);
};

export default Deposit;
