import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, Input, styled } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export const WalletsWrap = styled("div")(({ theme }) => ({
	// minHeight: "calc(100vh - 81px)",
	// maxHeight: "calc(100vh - 0)",
	// marginBottom: 65,
	// overflow: "auto",
}));
export const StyledTitle = styled("div")(({ theme }) => ({
	marginBottom: 55,
	marginTop: 65,
	marginRight: 60,
	fontSize: 20,
	fontWeight: 600,
}));

export const StyledButton = styled(Button)(({ theme }) => ({
	marginRight: 30,
	borderRadius: 3,
	textTransform: "unset",
	color: "#00b7f1",
	border: "1.5px solid #00b7f1",
	width: 119,
	height: 34,
	marginTop: 12,
	"&:hover": {
		border: "1.5px solid #00b7f1",
	},
}));

export const StyledButtonWithdraw = styled(Button)(({ theme }) => ({
	marginRight: 30,
	borderRadius: 3,
	textTransform: "unset",
	color: "#80848E",
	border: "1.5px solid #80848E",
	width: 119,
	height: 34,
	marginTop: 12,
	"&:hover": {
		border: "1.5px solid #80848E",
	},
}));

export const StyledButtonTable = styled(Button)(({ theme }) => ({
	textTransform: "unset",
	color: "#00b7f1",
}));

export const StyledBalanceTitle = styled("div")(({ theme }) => ({
	color: "#80848E",
	fontSize: 16,
	fontWeight: 400,
	lineHeight: "24px",
	paddingLeft: "64px",
}));

export const StyledBalanceValue = styled("div")(({ theme }) => ({
	color: "#000000",
	fontSize: 35,
	fontWeight: 500,
	lineHeight: "52px",
	paddingLeft: "64px",
}));

export const StyledBalanceValueCurrency = styled("span")(({ theme }) => ({
	color: "#80848E",
	fontSize: 35,
	fontWeight: 500,
	lineHeight: "52px",
}));

export const StyledTextField = styled(Input)(({ theme }) => ({
	color: "#80848E !important",
	fontFamily: "Poppins !important",
	fontSize: "14px !important",
	marginTop: 56,
	width: 350,

	"&:before": {
		borderColor: "#D1D1D1 !important",
	},
}));

export const StyledSearchIcon = styled(SearchIcon)(({ theme }) => ({
	color: "black",
}));

export const StyledWrapper = styled("div")(({ theme }) => ({
	background: "#F5F5F5",
	paddingTop: 50,
	minHeight: "calc(100vh - 81px)",
	padding: "20px 35px",
}));

export const StyledSubWrapper = styled("div")(({ theme }) => ({
	background: "#FFFFFF",
	paddingTop: 50,
	// minHeight: "calc(100vh - 81px)",
}));

export const StyledMarketsItem = styled(Box)(({ theme }) => ({
	border: "1px solid #F5F5F5",
	padding: 30,
}));

export const StyledMarketItemName = styled("span")(({ theme }) => ({
	marginLeft: 16,
}));

export const StyledMarketItemPercent = styled("span")(({ theme }) => ({
	color: "#008B06",
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
	borderTop: "2px solid #F5F5F5",
	borderBottom: "2px solid #F5F5F5",
	padding: "20px 0",
	background: "white",
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
	padding: "15px 0",
	color: "#000000",
}));

export const StyledTableCellThin = styled(TableCell)(({ theme }) => ({
	padding: "15px 0",
	width: 100,
	color: "#000000",
}));

export const StyledShortName = styled("span")(() => ({
	color: "#80848E",
	marginLeft: 5,
}));

export const StyledTableBody = styled(TableBody)(() => ({
	// background: "#FFF",
}));

export const StyledCoinImg = styled("img")(() => ({
	width: "25px",
	marginRight: 16,
	marginLeft: 16,
}));

export const StyledTableCellHead = styled(TableCell)(() => ({
	padding: "24px 0",
}));

export const StyledTableCellHeadCoin = styled(TableCell)(() => ({
	padding: "24px 22px",
}));

export const StyledSortImg = styled("img")(() => ({
	display: "block",
	cursor: "pointer",
	marginLeft: 13,
	"&:nth-child(1)": {
		transform: "rotate(180deg)",
		marginBottom: 5,
	},
}));

export const StyledLeftPadding = styled("div")(() => ({
	paddingLeft: "64px",
}));

export const StyledButtonHistory = styled(Button)(({ theme }) => ({
	marginRight: 30,
	borderRadius: 3,
	textTransform: "unset",
	color: "#FFFFFF",
	width: 211,
	height: 34,
	marginTop: 12,
	border: "none",
	background: "#A7A7A7",
	"&:hover": {
		color: "#FFFFFF",
		background: "#A7A7A7",
		border: "none",
	},
}));
