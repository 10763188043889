import { FC } from "react";

import { NavLink, useNavigate } from "react-router-dom";

import { Button } from "@mui/material";

import styles from "./deposit-success.module.scss";

export const DepositError: FC = () => {
	const navigate = useNavigate();
	const backToAmanah = () => {
		navigate("/dashboard");
	};
	return (
		<>
			<div className='h-screen w-full dark'>
				<div className={styles["depositSuccess-top-bar"]}>
					<NavLink to='/dashboard'>
						<a>
							<img src='/images/logo.png' alt='test' height={31} />
						</a>
					</NavLink>
				</div>
				<div className={styles["depositSuccess-wrapper"]}>
					<div className={styles["depositSuccess-circle"]}>
						<img alt='' src='/images/auth/circle1.svg' />
					</div>
					<div>
						<h3 className={styles["depositSuccess-title"]}>Deposit unsuccessful</h3>
						<p className={styles["depositSuccess-content"]}>Your account hasn’t been funded.</p>
						<p className={styles["depositSuccess-content"]}>Please try again!</p>
						<Button
							style={{ margin: "60px auto 0 auto", display: "flex" }}
							sx={{
								p: "0 !important",
								"&.MuiButtonBase-root:hover": {
									bgcolor: "transparent",
								},
							}}
							variant={"text"}
							onClick={backToAmanah}>
							<span className={styles["depositSuccess-backBtn"]}>Back to BitTrade</span>
							<img
								style={{ marginTop: "60px", marginLeft: "20px" }}
								src='/images/next.svg'
								alt='read more vector icon'
								width={13}
								height={11}
							/>
						</Button>
					</div>
					<div className={styles["depositSuccess-circle"]}>
						<img alt='' src='/images/auth/circle2.svg' />
					</div>
				</div>
			</div>
		</>
	);
};
