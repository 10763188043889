import "./dashboardStyles.scss";

// import Image from 'next/image'
import { FC } from "react";

import { useNavigate } from "react-router-dom";

const AffilatesBlock: FC = (): JSX.Element => {
	const router = useNavigate();
	return (
		<div className='mb14 advBlock'>
			<div style={{ zIndex: 1, textAlign: "center", display: "flex", borderRadius: 5 }}>
				<p className='advBlock_title'>
					Earn up to 50% commision, <br />
					<span className='advBlock_subtitle'>invite your friends now!</span>
				</p>
				<button
					style={{
						marginTop: "10px",
						cursor: "pointer",
						background: "none",
						outline: "none",
						border: "none",
					}}
					onClick={() => router("/dashboard/affiliates")}>
					<img
						src='/images/readMoreVectorIcon.svg'
						alt='read more vector icon'
						width={32}
						height={18}
					/>
				</button>
			</div>

			<img
				className='advBlock_image'
				src='/images/dashboardAdv.png'
				alt='dashboard background'
				// layout="fill"
			/>
		</div>
	);
};

export default AffilatesBlock;
