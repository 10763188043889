import { FC } from "react";
import { Box } from "@mui/material";

interface IProps {
	onClick: () => void;
}

export const BLogo: FC<IProps> = ({ onClick }) => {
	return (
		// <Box onClick={onClick} display={"inline-block"} component={"span"}>
		<img onClick={onClick} src='/images/logo.png' alt='' height={40} />
		// </Box>
	);
};
