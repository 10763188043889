import * as React from 'react';
import {
  useEffect,
  useState,
  useContext
} from 'react';

import { AdditionalLayout } from 'components';
import { useNavigate } from 'react-router-dom';
import { getCoinPath } from 'utils/common.utils';

// import AccountCircle from "@mui/icons-material/AccountCircle";
import {
  Box,
  styled,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
// import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import {
  setSelectedPair,
  setSelectedPairId,
  addPairChangingCounter
} from '../../store/exchange/exchange.slice';
import {
  getMarketThunk,
  currenciesGetThunk
} from '../../store/exchange/exchange.thunk';
import {
  StyledButton,
  StyledCoinIcon,
  StyledPercent,
  StyledSearchIcon,
  StyledShortName,
  StyledSubWrapper,
  StyledTableCell,
  StyledTableCellHeadText,
  StyledTableCellThin,
  StyledTableRow,
  StyledTextField,
  StyledTitle,
  StyledWrapper,
} from './Markets.styles';

import { IMarket } from 'types/exchange.types';
import { CentrifugeContext } from 'providers/CentrifugeProvider';

const StyledFieldWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  marginBottom: 83,
}));

const Markets =  () => {
  const [search, setSearch] = useState<string>('')
  const [marketsList, setMarketsList] = useState<IMarket[]>([])
  const dispatch = useAppDispatch()
  const nav = useNavigate()
  const {isExchangeLoading, markets, currencies} = useTypedSelector(state => state.exchange)
  const socketContext = useContext(CentrifugeContext)

  useEffect(() => {
    if(markets && !markets.length) {
      dispatch(getMarketThunk())
    } else {
      setMarketsList(markets)
    }

    if(!currencies.length) {
      dispatch(currenciesGetThunk())
    }
  }, [markets])

  useEffect(() => {
    // if (socketContext.rate) {
    //   const tmpMarkets: IMarket[] = []
    //   marketsList.forEach(market => {
    //     if (Array.isArray(socketContext.rate)) {
    //       console.log('array')
    //       socketContext.rate.forEach(coin => {
    //         if (market.coin === coin.coin.replace(/\s/g, '')) {
    //           tmpMarkets.push({
    //             ...market,
    //             lastPriceEUR: String(coin.lastPriceEUR),
    //             change24H: String(coin.change24H),
    //             volume24HEUR: String(coin.volume24hEUR)
    //           })
    //         } else {
    //           tmpMarkets.push(market)
    //         }
    //       })
    //     } else {
    //       if (market.coin === socketContext.rate?.coin.replace(/\s/g, '')) {
    //         tmpMarkets.push({
    //           ...market,
    //           lastPriceEUR: String(socketContext.rate.lastPriceEUR),
    //           change24H: String(socketContext.rate.change24H),
    //           volume24HEUR: String(socketContext.rate.volume24hEUR)
    //         })
    //       } else {
    //         tmpMarkets.push(market)
    //       }
    //     }
    //   })
    //   setMarketsList(tmpMarkets)
    // }
    if (socketContext.rate !== null) {
      const tmpMarkets: IMarket[] = []

      if (Array.isArray(socketContext.rate)) {
        const updatedCoins: Record<string, IMarket> = {}

        socketContext.rate.forEach(coin => {
          updatedCoins[coin.coin.replace(/\s/g, '')] = {
            coin: coin.coin.replace(/\s/g, ''),
            lastPriceEUR: String(coin.lastPriceEUR),
            volume24HEUR: String(coin.volume24hEUR),
            change24H: String(coin.change24H),
            pairId: String(coin.pairID)
          }
        })

        marketsList.forEach(market => {
          if (updatedCoins[market.coin]) {
            tmpMarkets.push({
              ...market,
              lastPriceEUR: updatedCoins[market.coin].lastPriceEUR,
              change24H: updatedCoins[market.coin].change24H,
              volume24HEUR: updatedCoins[market.coin].volume24HEUR,
            })
          } else {
            tmpMarkets.push(market)
          }
        })
      } else {
        marketsList.forEach(market => {
          if (socketContext.rate && !Array.isArray(socketContext.rate)) {
            if (market.coin === socketContext.rate!.coin.replace(/\s/g, '')) {
              tmpMarkets.push({
                ...market,
                lastPriceEUR: String(socketContext.rate.lastPriceEUR),
                change24H: String(socketContext.rate.change24H),
                volume24HEUR: String(socketContext.rate.volume24hEUR)
              })
            } else {
              tmpMarkets.push(market)
            }
          }
        })
      }

      setMarketsList(tmpMarkets)
    }
  }, [socketContext.rate])

  const computedMarkets = (() => {
    if(!marketsList) return []
    if(!search) return marketsList

    return marketsList.filter(market => market.coin.toLowerCase().includes(search.toLowerCase()))
  })()

  // const handleClickTrade = useCallback((pairId: number) => {
  //   return () => {
  //     // dispatch(setSelectedPairId(pairId))
  //   }
  // }, [])

  const setSelectedPairForTrade = (pair: string) => {
    const pairBody = currencies?.find(currency => currency.BaseCurrency === pair && currency.QuoteCurrency === 'USDT')

    if (pairBody) {
      dispatch(setSelectedPairId(pairBody.ID))
      dispatch(setSelectedPair(pairBody))
      dispatch(addPairChangingCounter())
      nav(`/exchange`)
    }
  }

  console.log(computedMarkets)

  return (
    <AdditionalLayout hideMenu>
      <StyledWrapper>
        <StyledSubWrapper>
          <StyledTitle>Markets</StyledTitle>
          <StyledFieldWrapper display="flex" justifyContent="center">
            <StyledTextField
                onChange={(e) => setSearch(e.target.value)}
              id="input-with-icon-textfield"
              startAdornment={
                <InputAdornment position="start">
                  <StyledSearchIcon />
                </InputAdornment>
              }
              placeholder="Search for"
            />
          </StyledFieldWrapper>

          <TableContainer style={{
            display: 'flex',
            justifyContent: 'center'
          }}>
            <Table sx={{ minWidth: 650, maxWidth: 1040 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <StyledTableCellHeadText>Coin</StyledTableCellHeadText>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    {" "}
                    <StyledTableCellHeadText>
                      Last Price (€)
                    </StyledTableCellHeadText>
                  </TableCell>
                  <TableCell>
                    <StyledTableCellHeadText>24H %</StyledTableCellHeadText>
                  </TableCell>
                  {/* <TableCell>
                    <StyledTableCellHeadText>
                      24H Volume
                    </StyledTableCellHeadText>
                  </TableCell> */}
                  {/* <TableCell>
                    <StyledTableCellHeadText>24H graph</StyledTableCellHeadText>
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {computedMarkets && computedMarkets.map((row, index) => (
                  <StyledTableRow
                    key={row.coin}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell align="center">
                      <StyledCoinIcon src={`/images/coins/${getCoinPath(row.coin)}`} alt={row.coin}/>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.coin}{" "}
                      <StyledShortName>{row.coin}</StyledShortName>
                    </StyledTableCell>
                    <StyledTableCell sx={{ color: '#000000 !important' }} align="left">€{row.lastPriceEUR}</StyledTableCell>
                    <StyledTableCell align="left">
                      <StyledPercent isPositive={Number(row.change24H) >= 0}>
                        {+row.change24H > 0 ? "+" : ""} {Number(row.change24H).toFixed(4)} % 
                      </StyledPercent>
                    </StyledTableCell>
                    {/* <StyledTableCellThin align="left">
                    €{Number(row.volume24HEUR).toLocaleString()}
                    </StyledTableCellThin> */}
                    {/* <StyledTableCellThin align="center">
                      <StyledImg src={"/images/marketGraph.svg"} />
                    </StyledTableCellThin> */}
                    <StyledTableCell align="center">
                      <StyledButton variant="outlined" onClick={() => setSelectedPairForTrade(row.coin)}>Trade</StyledButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* {items.map((item, index) => (
            <MarketItem item={item} key={index} />
          ))} */}
        </StyledSubWrapper>
      </StyledWrapper>
    </AdditionalLayout>
  );
};

export default Markets