import axios from "axios";
import {authApi} from "./auth.api";
import {storageService} from "../services/storage.service";
import {apiBaseUr} from "../app.config";

export const instance = axios.create({
    baseURL: apiBaseUr
})

const configs: any[] = []

instance.interceptors.response.use(
    (res) => res.data,
    async (error) => {
      console.log(error)
      const configGlobal = error?.config;
  
      if (error.response.status === 401 && configs.length < 1) {
        configs.push(configGlobal);
        try {
          const res = await authApi.refreshToken(
            storageService.getRefreshToken
          );
          storageService.setTokens = res;
          window.location.reload();
        } catch (err) {
          storageService.removeTokens();
          window.location.pathname = "/signin";
        }
    }
      return Promise.reject(error);
    }
);

instance.interceptors.request.use(
    (config) => {
        if ((!(config.headers as any)['Authorization'] || (config.headers as any)['x-refresh-token'])
            && config.url !== '/api/v1/refresh' && storageService.getAccessToken) {
            config!.headers!.Authorization = `Bearer ${storageService.getAccessToken}`
        }
        return config
    })
